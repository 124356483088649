<template>
  <b-row>
    <b-col cols="12">
      <h1>Bo3</h1>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
}
</script>
